import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as fromDrafts from "./drafts.actions";
import { Draft } from './drafts.model';

export interface DraftsState {
  entities: Draft[];
  isSending: boolean;
  error: string | any;
}

export const initialState: DraftsState = {
  entities: [],
  isSending: false,
  error: null
};

export function reducer(
  state = initialState,
  action: fromDrafts.DraftsActions
): DraftsState {

  switch (action.type) {
    case fromDrafts.ActionTypes.CleanAllDrafts: {
      return initialState;
    }

    case fromDrafts.ActionTypes.AddDraft: {
      return {
        ...state,
        entities: [...state.entities, action.payload.data]
      };
    }

    case fromDrafts.ActionTypes.RemoveDraft: {
      return {
        ...state,
        entities: state.entities.filter(item => item.id !== action.payload.data),
      };
    }

    default: {
      return state;
    }
  }
}

export const getDraftsState = createFeatureSelector<DraftsState>('drafts');

export const selectAllDrafts = createSelector(getDraftsState, (state) => (state.entities));

export const selectDraftByID = (id: string) => createSelector(
  selectAllDrafts,
  (allDrafts) => allDrafts.find(draft => draft.id === id)
);

export const selectAllDraftsByProjectID = (id: string) => createSelector(
  selectAllDrafts,
  (allDrafts) => {
    if (allDrafts.length > 0) {
      return allDrafts.filter(draft => draft.project_id === id)
    } else {
      return [];
    }
  }
);

export const selectAllDraftsBySectionID = (id: string) => createSelector(
  selectAllDrafts,
  (allDrafts) => {
    if (allDrafts.length > 0) {
      return allDrafts.filter(draft => draft.section_id === id)
    } else {
      return [];
    }
  }
);



export const getDraftByProjectId = (state: DraftsState, props: { id: string }) =>
  state.entities.find(task => task.projectId === props.id);

export const getDraftBySectionId = (state: DraftsState, props: { id: string }) =>
  state.entities.find(task => task.sectionId === props.id);

// export const getLoading = (state: DraftsState) => state.loading;

export const getError = (state: DraftsState) => state.error;
